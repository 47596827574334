import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/join-our-list/hero"
import SubForm from "./screens/join-our-list/form"

const ThankYou = () => {
  return (
    <React.Fragment>
      <Hero />
      <SubForm />
    </React.Fragment>
  )
}

export default ThankYou

export const Head = () => (
  <Seo
    title="Join our List"
    description="Subscribe to the Visby Medical mailing list."
    image="/meta/join-our-list.jpg"
    recaptcha
  />
)
